
import React from "react";
import BackgroundImg from "../Images/rhone.svg";
import Stock1 from "../Images/img-1.jpg";
import Custom1 from "../Images/custom-1.svg";
import { Link } from 'react-router-dom';

export default function StudentFee() {

    const divStyle = {
        backgroundColor: "#1B283F",
        backgroundPosition: "0 calc(100% + 0.5rem)",
        backgroundSize: "100% auto",
        backgroundImage: `url(${BackgroundImg})`,
    };

      const divStyle1 = {
        backgroundColor: "#4AB58E",
        backgroundPosition: "calc(100% + 1rem)",
        backgroundSize: "25% auto",
        backgroundImage: `url(${Custom1})`,
    };
    
    return (
        <div className="d-flex flex-column flex-root">
			<div className="page d-flex flex-row flex-column-fluid">
				<div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
					<div id="kt_header" className="header" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
						<div className="container-xxl d-flex flex-grow-1 flex-stack">
							<div className="d-flex align-items-center me-5">
								<div className="d-lg-none btn btn-icon btn-active-color-primary w-30px h-30px ms-n2 me-3" id="kt_header_menu_toggle">
									<i className="ki-duotone ki-abstract-14 fs-1">
										<span className="path1"></span>
										<span className="path2"></span>
									</i>
								</div>
								<a href="index.html">
									<img alt="Logo" src="assets/media/logos/demo16.svg" className="h-25px h-lg-30px me-2 me-lg-9" />
								</a>
							</div>
							<div className="topbar d-flex align-items-stretch flex-shrink-0" id="kt_topbar">
								<div className="d-flex align-items-center ms-2 ms-lg-4">
									<div className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-warning" id="kt_activities_toggle">
										<i className="ki-duotone ki-chart-line fs-1">
											<span className="path1"></span>
											<span className="path2"></span>
										</i>
									</div>
								</div>
								<div className="d-flex align-items-center ms-2 ms-lg-4">
									<div className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-success position-relative" id="kt_drawer_chat_toggle">
										<i className="ki-duotone ki-message-text fs-1">
											<span className="path1"></span>
											<span className="path2"></span>
											<span className="path3"></span>
										</i>
										<span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle ms-7 mb-3 animation-blink"></span>
									</div>
								</div>
								<div className="d-flex align-items-center ms-2 ms-lg-4">
									<div className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-primary" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
										<i className="ki-duotone ki-category fs-1">
											<span className="path1"></span>
											<span className="path2"></span>
											<span className="path3"></span>
											<span className="path4"></span>
										</i>
									</div>
									
								</div>
								<div className="d-flex align-items-center ms-2 ms-lg-4" id="kt_header_user_menu_toggle">
									<div className="cursor-pointer symbol symbol-30px symbol-lg-40px" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
										<img className="symbol symbol-30px symbol-lg-40px" src="assets/media/avatars/300-3.jpg" alt="user" />
									</div>
									<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" data-kt-menu="true">
										<div className="menu-item px-3">
											<div className="menu-content d-flex align-items-center px-3">
												<div className="symbol symbol-50px me-5">
													<img alt="Logo" src="assets/media/avatars/300-3.jpg" />
												</div>
												<div className="d-flex flex-column">
													<div className="fw-bold d-flex align-items-center fs-5">Max Smith 
													<span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">Pro</span></div>
													<a href="#" className="fw-semibold text-muted text-hover-primary fs-7">max@kt.com</a>
												</div>
											</div>
										</div>
										<div className="separator my-2"></div>
										<div className="menu-item px-5">
											<a href="account/overview.html" className="menu-link px-5">My Profile</a>
										</div>
										<div className="menu-item px-5">
											<a href="apps/projects/list.html" className="menu-link px-5">
												<span className="menu-text">My Requests</span>
												<span className="menu-badge">
													<span className="badge badge-light-danger badge-circle fw-bold fs-7">3</span>
												</span>
											</a>
										</div>
										<div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
											<a href="#" className="menu-link px-5">
												<span className="menu-title position-relative">Language 
												<span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">English 
												<img className="w-15px h-15px rounded-1 ms-2" src="assets/media/flags/united-states.svg" alt="" /></span></span>
											</a>
										</div>
										<div className="menu-item px-5">
											<Link to='/'>
												<a className="menu-link px-5">Sign Out</a>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
					<div className="toolbar py-3 py-lg-5" id="kt_toolbar">
						<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
							<div className="page-title d-flex flex-column">
								<ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-1">
									<li className="breadcrumb-item text-gray-700 fw-bold lh-1">
										<a href="index.html" className="text-gray-600 text-hover-primary">
											<i className="ki-duotone ki-home text-gray-700 fs-6"></i>
										</a>
									</li>
									<li className="breadcrumb-item">
										<i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
									</li>
									<li className="breadcrumb-item text-gray-700 fw-bold lh-1">Account</li>
									<li className="breadcrumb-item">
										<i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
									</li>
									<li className="breadcrumb-item text-gray-500">Overview</li>
								</ul>
                                <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">Dashboard</h1>
								<span className="page-desc text-muted fs-7 fw-semibold pt-1"></span>
							</div>
						</div>
					</div>
					<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
						<div className="content flex-row-fluid" id="kt_content">
							<div className="row g-5 g-lg-10">
								<div className="col-xl-4 mb-xl-10">
									<div className="card bgi-no-repeat h-xl-100 mb-5 mb-lg-10" style={divStyle}>
										<div className="card-body">
											
										</div>
									</div>
								</div>
								<div className="col-xl-4">
									<div className="row g-5 g-lg-10">
										<a href="#" className="card bgi-no-repeat h-150px mb-xl-10" style={divStyle1}>
											<div className="card-body d-flex flex-column align-items-start justify-content-center">
												<h3 className="text-white fw-bold mb-3">30% Off Themes</h3>
												<p className="text-white fs-7">Get your discounted themes of the month 
												<br />No hassle, no worries, no fuss 
												<br />Instant rewards, everyday</p>
											</div>
										</a>
										<div className="col-lg-6 mb-lg-10">
											<a href="#" className="card bg-danger h-150px">
												<div className="card-body d-flex flex-column justify-content-between">
													<i className="ki-duotone ki-element-11 text-white fs-2hx ms-n1 flex-grow-1">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
													</i>
													<div className="d-flex flex-column">
														<div className="text-white fw-bold fs-1 mb-0 mt-5">3,900</div>
														<div className="text-white fw-semibold fs-6">Author Sales</div>
													</div>
												</div>
											</a>
										</div>
										<div className="col-lg-6 mb-5 mb-lg-10">
											<a href="#" className="card bg-body h-150px">
												<div className="card-body d-flex flex-column py-6 px-6">
													<div className="d-flex flex-column flex-grow-1 mb-5">
														<span className="text-gray-500 fw-semibold me-2 fs-7">Author Stats</span>
														<span className="fw-bold fs-1 text-gray-900">4.9M</span>
													</div>
													<div className="progress h-7px bg-info bg-opacity-25">
														<div className="progress-bar bg-info" role="progressbar" style={{width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
													</div>
												</div>
											</a>
										</div>
									</div>
									<div className="row g-5 g-lg-10">
										<div className="col-lg-6 mb-lg-10">
											<div className="card h-150px bgi-no-repeat bgi-size-cover bgi-position-y-center h-150px" style={{ backgroundImage: `url(${Stock1})` }}>
												<div className="card-body p-6">
													<a href="#" className="text-black text-hover-primary fw-bold fs-2" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">Company</a>
												</div>
											</div>
										</div>
										<div className="col-lg-6 mb-5 mb-lg-10">
											<a href="#" className="card bg-primary h-150px">
												<div className="card-body d-flex flex-column justify-content-between">
													<i className="ki-duotone ki-chart-pie-simple text-white fs-2hx ms-n1 flex-grow-1">
														<span className="path1"></span>
														<span className="path2"></span>
													</i>
													<div className="d-flex flex-column">
														<div className="text-white fw-bold fs-1 mb-0 mt-5">75%</div>
														<div className="text-white fw-semibold fs-6">Success Rate</div>
													</div>
												</div>
											</a>
										</div>
									</div>
									
								</div>
								<div className="col-xl-4 mb-xl-10">
									<div className="card bgi-no-repeat h-xl-100 mb-5 mb-lg-10">
										<div className="card-body">
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold fs-3 mb-1">Apply Request</span>
											</h3>
											<form>
												<div className="min-w-100px mb-3">
													<label className="form-label">Student Name</label>
													<select className="form-select form-select-soid form-select-sm">
														<option disabled selected>Choose student</option>
														<option value="1">Rohit</option>
														<option value="2">Mahaveer</option>
														<option value="3">Laxmi Shivani</option>
													</select>
												</div>
												<div className="mb-10 fv-row">
													<label className="required form-label mb-3">Visitor Name</label>
													<input type="text" className="form-control form-control-lg form-control-solid" name="campaign_name" placeholder="" value="" />
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
						<div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
							<div className="text-gray-900 order-2 order-md-1">
								<span className="text-muted fw-semibold me-1">2024&copy;</span>
								<a href="https://keenthemes.com" target="_blank" className="text-gray-800 text-hover-primary">Keenthemes</a>
							</div>
							<ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
								<li className="menu-item">
									<a href="https://keenthemes.com" target="_blank" className="menu-link px-2">About</a>
								</li>
								<li className="menu-item">
									<a href="https://devs.keenthemes.com" target="_blank" className="menu-link px-2">Support</a>
								</li>
								<li className="menu-item">
									<a href="https://1.envato.market/EA4JP" target="_blank" className="menu-link px-2">Purchase</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
    );
}
