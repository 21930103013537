

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, BASE_SUPERID } from './Config';
import '../CSS/signinnew.css';
import BgImg from '../Images/vbb.jpg';
import BirlaLogo from '../Images/birla.png';

export default function SigninNew() {

    const navigate = useNavigate();

    const [userDetails, setUserDetails] = useState([]);
    const [signinDisplay, setSigninDisplay] = useState('block');
    const [loading, setLoading] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [password, setPassword] = useState('');
    const [mobileNumberMessage, setMobileNumberMessage] = useState('');
    const [showVideoPopup, setShowVideoPopup] = useState(false);

    const [showNewPassword, setShowNewPassword] = useState(false);

    const handlePasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };
    
    const handleMobileNumber = (e) => {
        setMobileNumber(e.target.value);
        setMobileNumberMessage('');
    }
    const handlePassword = (e) => {
        setPassword(e.target.value);
    }
    
    const handleDetailsSubmit = async (e) => {
        e.preventDefault();
    
        setLoading(true);
        setMobileNumberMessage('');
    
        if (mobileNumber.length !== 10) {
            setLoading(false);
            setMobileNumberMessage('*Mobile number should be 10 digits.');
            return;
        }
    
        try {
            const response = await fetch(`${API_BASE_URL}login/signin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MobileNo: mobileNumber,
                    Password: password
                })
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const result = await response.json();
            console.log(result)
    
            if (result.Status) {
                saveUserDetailsToSessionStorage(mobileNumber, password);
                sessionStorage.setItem('userDataFromSession', JSON.stringify(result.ResultData));
    
                setLoading(false);
                navigate('/dashboard');
            } else {
                setLoading(false);
                setMobileNumberMessage('*Invalid credentials.');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
            setMobileNumberMessage('*Invalid credentials.');
        }
    };
    
    const handleEditButtonClick = (item) => {
        setShowVideoPopup(true);
    };

    const handleEditClosePopup = () => {
        setShowVideoPopup(false);
    };
    
    const saveUserDetailsToSessionStorage = (mobile) => {
        const userDetails = {
            mobile: mobile,
            password: password,
            sessionExpiration: Date.now() + 500 * 60 * 1000,
        };
        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
    };

    useEffect(() => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            const storedUserDetails = JSON.parse(userDetailsString);
            setUserDetails(storedUserDetails);
        }
    }, []);

    return(
        <div>
            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 text-center mb-5">
                            <h2 className="">
                                <img src={BirlaLogo} style={{ width: '8rem' }} alt="Birla Logo" />
                            </h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-7 col-lg-5">
                            <div className="wrap">
                                <div className="img" style={{ backgroundImage: `url(${BgImg})` }}></div>
                                <div className="login-wrap p-3 p-md-5">
                                    <div className="d-flex">
                                        <div className="w-100">
                                            <h1 className="mb-4 fw-semibold text-center">Sign In</h1>
                                        </div>       
                                    </div>
                                    <form onSubmit={handleDetailsSubmit} className={`card-body m-3 d-${signinDisplay}`}>
                                        <div className="form-group mt-1">
                                            <label className="form-label" htmlFor="username">Mobile Number
                                                <i className="fa-regular fa-circle-question ms-2" 
                                                    title='Mobile number will registered in school.' 
                                                    style={{ cursor: 'pointer' }}
                                                ></i>
                                            </label>
                                            <input 
                                                type="text" 
                                                className="form-control mb-2" 
                                                placeholder="Enter registered mobile number"
                                                autoComplete="off" 
                                                onChange={handleMobileNumber}
                                                pattern="\d{10}"
                                                title="Please enter exactly 10 digits"
                                                required
                                                />
                                        </div>
                                        <div className="form-group mt-1">
                                            <label className="form-label" htmlFor="newPassword">New Password</label>
                                            <div className="input-group mb-2">
                                                <input 
                                                    type={showNewPassword ? 'text' : 'password'}
                                                    className="form-control bg-light-primary" 
                                                    placeholder="Enter your new password"
                                                    autoComplete="off" 
                                                    onChange={handlePassword}
                                                    required
                                                />
                                                <div className="input-group-append">
                                                    <span 
                                                        className="input-group-text cursor-pointer bg-light-info border border-light-danger"
                                                        style={{ height: '3.4rem' }}
                                                        onClick={handlePasswordVisibility}
                                                    >
                                                        {showNewPassword ? (
                                                            <i className="fa-regular fa-eye-slash fs-4"></i>
                                                        ) : (
                                                            <i className="fa-regular fa-eye fs-4"></i>
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                            <div></div>
                                            <Link to='/forgot-password'>
                                                <a className="link-primary">
                                                    Forgot Password ?
                                                </a>
                                            </Link>
                                        </div>
                                        <p className='fw-semibold text-danger'>{mobileNumberMessage}</p>
                                        <div className="form-group">
                                            <button type="submit" className="form-control btn btn-success rounded submit px-3">{loading ? 'Submitting..' : 'Submit'}</button>
                                        </div>
                                        
                                    </form>
                                </div>
                                <div className='text-center mb-4'>
                                    <a onClick={handleEditButtonClick} className='text-primary' style={{ cursor: 'pointer' }}>Watch Demo<i className="fa-regular fa-circle-play ms-1 text-primary"></i></a>
                                    {/* href='https://youtu.be/aqo6S-ghPM4' target='_blank' rel="noreferrer" */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
	        </section>

            {showVideoPopup && (
                <div className="popup ">
                    <div className="popup-content card">
                        <div className="d-flex">
                            <h3 className="text-start">Demo  Video</h3>
                            <p
                                style={{ cursor: "pointer" }}
                                onClick={handleEditClosePopup}
                                className="text-dark d-flex ms-auto"
                            >
                                <i className="fa-solid fa-xmark"></i>
                            </p>
                        </div>
                        <iframe width="100%" height="400px" src="https://www.youtube.com/embed/aqo6S-ghPM4?si=sR_DwZflf8wt_0cD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            )}
        </div>
    )
}