
import React, { useState, useEffect, useRef } from "react";
import { API_BASE_URL } from "./Config";
import SchoolBoyImg from "../Images/schoolboy.jpg";
import SchoolGirlImg from "../Images/schoolgirl.jpeg";
import BirlaLogo from "../Images/birla-logo.png";
import { Link } from "react-router-dom";
import { Popover } from "antd";
import DownlaodPDfGif from '../Images/pdf.gif';
import BirlaPrintLogo from '../Images/birlaprint.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Collapse } from 'antd';
import '../CSS/custom.css';
import VisitSvg from '../Images/visit.svg';

export default function StudentInfo() {

  const [studentsData, setStudentsData] = useState([]);
  const [userMobileNo, setUserMobileNo] = useState([]);

  const [data, setData] = useState([]);
  // const [selectedStudent, setSelectedStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [studentInfoData, setStudentInfoData] = useState([]);
  const [studentFeesData, setStudentFeesData] = useState([]);
  const [studentFeePaidAmt, setStudentFeePaidAmt] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [paidAmount, setPaidAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [notificationsData, setNotificationsData] = useState([]);
  
  const [selectedStudentRegId, setSelectedStudentRegId] = useState('');
  const [selectedStudentTrnId, setSelectedStudentTrnId] = useState('');
  const [selectedStudentGender, setSelectedStudentGender] = useState("");
  const [selectedStudentStandardId, setSelectedStudentStandardId] = useState('');

   useEffect(() => {
    const storedStudentData = sessionStorage.getItem("studentsData");
    const storedUserMobileNo = sessionStorage.getItem("userDetails");
    const storedRequests = sessionStorage.getItem("requestsData");
    // const notificationData = sessionStorage.getItem("notificationsData");

    if (storedStudentData && storedUserMobileNo) {
      const parsedMobileNoData = JSON.parse(storedUserMobileNo);
      const parsedData = JSON.parse(storedStudentData);
      const parsedRequestsData = JSON.parse(storedRequests);
      setUserMobileNo(parsedMobileNoData);
      setStudentsData(parsedData);
      setData(parsedRequestsData);
      // setData(parsedRequestsData);
      // setNotificationsData(notificationData);
    }
  }, []);

  useEffect(() => {
     if (studentsData[0]?.SuperId) {
      const fetchNotifications = async () => {
        setLoading(true);
        try {
          const response = await fetch(
            `${API_BASE_URL}comm/GetLatestNotifications?SuperId=${studentsData[0]?.SuperId}&Type=6&RegId=${selectedStudentRegId}`
          );
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const jsonData = await response.json();
          setNotificationsData(jsonData.ResultData);
          sessionStorage.setItem('notificationsData', JSON.stringify(jsonData.ResultData));
        } catch (error) {
          console.error(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchNotifications();
    }
  }, [studentsData, selectedStudentRegId]);

  useEffect(() => {

    const savedStudentRegId = sessionStorage.getItem('selectedStudentRegId');
    const savedStudentTrnId = sessionStorage.getItem('selectedStudentTrnId');
    const savedStudentStandId = sessionStorage.getItem('selectedStudentStandId');
    const savedStudentGender = sessionStorage.getItem('selectedStudentGender');

    setSelectedStudentRegId(savedStudentRegId);
    setSelectedStudentTrnId(savedStudentTrnId);
    setSelectedStudentStandardId(savedStudentStandId);
    setSelectedStudentGender(savedStudentGender);

  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedStudentRegId && studentsData[0]?.SuperId) {
        setLoading(true);
        try {
          const response = await fetch(
            `${API_BASE_URL}student/GetStudentInfo?SuperId=${studentsData[0]?.SuperId}&RegId=${selectedStudentRegId}`
          );
          
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const jsonData = await response.json();
          setStudentInfoData(jsonData.ResultData[0]);
        } catch (error) {
          console.log(error.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [selectedStudentRegId]);

  useEffect(() => {
    const fetchData = async () => {
        if (selectedStudentTrnId) {
            setLoading(true);
            try {
              if (selectedStudentTrnId) {
                  const response = await fetch(`${API_BASE_URL}student/GetStudentFees?SttId=${selectedStudentTrnId}`);
                  const responseAmt = await fetch(`${API_BASE_URL}student/GetStudentPaidAmt?SttId=${selectedStudentTrnId}`);

                  if (!response.ok) {
                      throw new Error("Network response was not ok");
                  }
  
                  const jsonData = await response.json();
                  const jsonDataAmt = await responseAmt.json();

                  setStudentFeesData(jsonData.ResultData);
                  setStudentFeePaidAmt(jsonDataAmt.ResultData);
                  setLoading(false);
              }
            } catch (error) {
                setLoading(false);
                console.log(error.message);
            } finally {
                setLoading(false);
            }
        }
    };

    fetchData();
  }, [selectedStudentTrnId]);

  const content = (
    <div
      className=" menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-245px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src="assets/media/avatars/300-3.jpg" />
          </div>
          <div className="d-flex flex-column">
            <div className="fw-bold d-flex align-items-center fs-5">
              {studentsData[0]?.Father}
              <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                Guardian
              </span>
            </div>
            <a
              href="#"
              className="fw-semibold text-muted text-hover-primary fs-7"
            >
              {studentsData[0]?.MobileNo}
            </a>
          </div>
        </div>
      </div>
      <div className="separator my-2"></div>
      <div className="menu-item px-5">
        <Link to="/student-info">
          <a className="menu-link px-5"><i className="fa-solid fa-graduation-cap text-primary me-2"></i>Student Info</a>
        </Link>
      </div>
      <div className="menu-item px-5">
        <Link to="/change-password">
          <a className="menu-link px-5"><i className="fa-solid fa-key text-danger me-2"></i>Change Password</a>
        </Link>
      </div>

      <div className="menu-item px-5">
        <Link to="/logout">
          <a
            className="menu-link px-5 text-danger"
          >
            Sign Out <i className="fa-solid fa-arrow-right-from-bracket ms-2 text-danger mt-1"></i>
          </a>
        </Link>
      </div>
    </div>
  );

  useEffect(() => {
    const totalPaidAmount = studentFeesData.reduce((total, item) => total + item.PaidAmount, 0);
    const totalAmount = studentFeesData.reduce((total, item) => total + item.Amount, 0);

    const totalPenidngAmount = totalAmount - totalPaidAmount;

    setPaidAmount(totalPaidAmount);
    setTotalAmount(totalAmount);
    setPendingAmount(totalPenidngAmount);
  }, [studentFeesData]);

  const items = notificationsData && notificationsData.map((item, index) => ({
    key: index.toString(),
    label: item.Title,
    children: <p>{item.Description}</p>,
  }));

  const contentNoti = (
    <div
      className=" menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 "
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          Notifications <i className="fa-regular fa-comments ms-2 fs-3 text-info"></i>
        </div>
      </div>
      <div className="separator my-2"></div>
     
      <div className="menu-item px-1">
        <a className="menu-link ">
          {notificationsData && notificationsData.length > 0 ? (
            <>
              {/* <span className="bullet bg-primary me-3"></span> */}
              <Collapse  ghost items={items} />
            </>
          ) : (
            'No notifications available.'
          )}
        </a>
      </div>
    </div>
  );

  useEffect(() => {
    if (data && data && data.length > 0) {
      const sortedData = [...data].sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn));
      const recentFive = sortedData.slice(0, 5);
      setRecentData(recentFive);
    }
  }, [data && data.length > 0]);

  const getStatusInfo = (statusId) => {
    switch (statusId) {
      case 1:
        return { name: "Pending", color: "orange" };
      case 2:
        return { name: "Success", color: "green" };
      case 3:
        return { name: "Rejected", color: "red" };
      // case 4:
      //   return { name: "Expired", color: "gray" };
      case 4:
        return { name: "Picked", color: "Green" };
      case 5:
        return { name: "Droped", color: "Green" };
      default:
        return { name: "Unknown", color: "black" }; // Default to black color for unknown statuses
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
        case 1:
            return 'badge-light-warning';
        case 2:
            return 'badge-light-success';
        case 3:
            return 'badge-light-danger';
        case 4:
            return 'badge-light-info';
        case 5:
            return 'badge-light-primary';
        default:
            return 'badge-light-light';
    }
  };

  const removeAmPm = (timeString) => {
    return timeString.replace(/\s[AP]M$/, ""); // Replace "AM" or "PM" followed by a space at the end of the string with an empty string
  };

  const removeAmPmAndTime = (dateTimeString) => {
    return dateTimeString.replace(/\s[AP]M$/, "").split(" ")[0]; // Remove "AM" or "PM" suffix and then split by space to get only date
  };

  const receiptRef = useRef();

  const handleDownloadPdf = async () => {
    const element = receiptRef.current;

    // Ensure the element is temporarily visible for rendering
    element.style.display = 'block';

    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: 'a4'
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('receipt.pdf');

    // Hide the element again if needed
    element.style.display = 'none';
  };

  const formatAmount = (amount) => {
    if (!amount) return '';
    return amount.toLocaleString('en-IN'); // Format according to Indian numbering system
  };

  return (
    <div className="d-flex flex-column flex-root">
      <div className="page d-flex flex-row flex-column-fluid">
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div
            id="kt_header"
            className="header fixed-top"
            style={{ position: "sticky", top: 0, zIndex: 1030 }}
            data-kt-sticky="true"
            data-kt-sticky-name="header"
            data-kt-sticky-offset="{default: '200px', lg: '300px'}"
          >
            <div className="container-xxl d-flex flex-grow-1 flex-stack">
              <div className="d-flex align-items-center me-5">
                <Link to="/dashboard">
                  <a>
                    <img
                      alt="Logo"
                      src={BirlaLogo}
                      className="h-70px h-lg-80px me-2 me-lg-9"
                    />
                  </a>
                </Link>
              </div>
              <div
                className="topbar d-flex align-items-stretch flex-shrink-0"
                id="kt_topbar"
              >
                <div className="d-flex align-items-center ms-2 ms-lg-4">
                  <div
                    className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-success position-relative me-2"
                    id="kt_drawer_chat_toggle"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                  >
                    <i className="fa-solid fa-code-pull-request fs-2"></i>
                  </div>
                  <Popover placement="bottomRight" content={contentNoti}>
                    <div className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-success position-relative">
                      <i className="fa-regular fa-bell fs-2"></i>
                      <span className="bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle ms-7 mb-3 animation-blink"></span>
                    </div>
                  </Popover>
                </div>
                <div
                  className="d-flex align-items-center ms-2 ms-lg-4"
                  id="kt_header_user_menu_toggle"
                >
                  <div
                    className="cursor-pointer symbol symbol-30px symbol-lg-40px"
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                  >
                    <Popover placement="bottomRight" content={content}>
                      <img
                        className="symbol symbol-30px symbol-lg-40px"
                        src="assets/media/avatars/300-3.jpg"
                        alt="user"
                      />
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
            <div
              id="kt_toolbar_container"
              className="container-xxl d-flex flex-stack flex-wrap"
            >
              <div className="page-title d-flex flex-column">
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-1">
                  <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                    <Link to="/dashboard">
                      <a className="text-gray-600 text-hover-primary">
                        <i className="ki-duotone ki-home text-gray-700 fs-3"></i>
                      </a>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                  </li>
                  <li className="breadcrumb-item text-gray-500">View User</li>
                </ul>
                <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                  View User Details
                </h1>
              </div>
            </div>
          </div>

          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start container-xxl"
          >
            <div className="content flex-row-fluid" id="kt_content">
              <div className="d-flex flex-column flex-lg-row">
                <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
                  <div className="card mb-5 mb-xl-8 bg-light-primary">
                    <div className="card-body">
                      <div className="d-flex flex-center flex-column py-5">
                        <div className="symbol symbol-100px symbol-circle mb-7">
                          {studentInfoData.Gender == 1 ? (
                            <img
                              src={SchoolBoyImg}
                              className="d-flex m-auto mb-2"
                              alt="user-icon"
                              // style={{ width: "5rem" }}
                            />
                          ) : (
                            <img
                              src={SchoolGirlImg}
                              className="d-flex m-auto mb-2"
                              alt="user-icon"
                              // style={{ width: "5rem" }}
                            />
                          )}
                        </div>
                        <a
                          href="#"
                          className="fs-3 text-gray-800 text-hover-primary fw-bold mb-3"
                        >
                          {studentInfoData && studentInfoData.StudentName}
                        </a>
                        <div className="mb-9">
                          <div className="badge badge-lg badge-light-primary d-inline">
                            {studentInfoData && studentInfoData.Gender === 1 ? 'Male' : 'Female'}
                          </div>
                        </div>
                        <div className="fw-bold mb-3">
                          Fee Amount
                         
                        </div>
                        <div className="d-flex flex-wrap justify-content-center">
                          <div className="custom-card1 border border-gray-300 bg-info rounded py-3 px-3 mb-3">
                            <div className="fs-4 fw-bold text-white">
                              <span className="w-75px">{formatAmount(totalAmount)}</span>
                              <i className="ki-duotone ki-arrow-up fs-2 text-white">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="fw-semibold text-white">Total</div>
                          </div>
                          <div className="custom-card1 border border-gray-300 bg-success rounded py-3 px-3 mx-4 mb-3">
                            <div className="fs-4 fw-bold text-white">
                              <span className="w-50px">{formatAmount(paidAmount)}</span>
                              <i className="ki-duotone ki-arrow-down fs-2 text-white">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="fw-semibold text-white">Paid</div>
                          </div>
                          <div className="custom-card1 border border-gray-300 bg-danger rounded py-3 px-3 mb-3">
                            <div className="fs-4 fw-bold text-white">
                              <span className="w-50px">{formatAmount(pendingAmount)}</span>
                              <i className="ki-duotone ki-arrow-up fs-2 text-white">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </div>
                            <div className="fw-semibold text-white">Due</div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-stack fs-4 py-3">
                        <div
                          className="fw-bold rotate collapsible"
                          data-bs-toggle="collapse"
                          href="#kt_user_view_details"
                          role="button"
                          aria-expanded="false"
                          aria-controls="kt_user_view_details"
                        >
                          Details
                          <span className="ms-2 rotate-180">
                            <i className="ki-duotone ki-down fs-3"></i>
                          </span>
                        </div>
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-trigger="hover"
                          title="Edit customer details"
                        ></span>
                      </div>
                      <div className="separator"></div>
                      <div id="kt_user_view_details" className="collapse show">
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>Admission Date</td>
                              <th className="fw-bold">
                                {studentInfoData && new Date(studentInfoData.AdmissionDate).toLocaleDateString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                })}
                              </th>
                            </tr>
                            <tr>
                              <td>Admission No</td>
                              <th className="fw-bold">{studentInfoData && studentInfoData.AdmissionNo}</th>
                            </tr>
                            <tr>
                              <td>Section</td>
                              <th className="fw-bold">{studentInfoData && studentInfoData.Section}</th>
                            </tr>
                            <tr>
                              <td>Standard</td>
                              <th className="fw-bold">{studentInfoData && studentInfoData.Standard}</th>
                            </tr>
                            <tr>
                              <td>Religion</td>
                              <th className="fw-bold">{studentInfoData && studentInfoData.ReligionNm}</th>
                            </tr>
                            <tr>
                              <td>DOB</td>
                              <th className="fw-bold">
                                {/* {studentInfoData && studentInfoData.DOB} */}
                                {studentInfoData && new Date(studentInfoData.DOB).toLocaleDateString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                })}
                              </th>
                            </tr>
                            <tr>
                              <td>Caste</td>
                              <th className="fw-bold">{studentInfoData && studentInfoData.CasteNm}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-lg-row-fluid ms-lg-15">
                  <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8">
                    <li className="nav-item">
                      <a
                        className="nav-link text-active-primary pb-4 active"
                        data-bs-toggle="tab"
                        href="#kt_user_view_overview_tab"
                      >
                        Student Info<i className="fa-solid fa-graduation-cap ms-1 fs-2"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-active-primary pb-4"
                        data-kt-countup-tabs="true"
                        data-bs-toggle="tab"
                        href="#kt_user_view_overview_security"
                      >
                        Fee Details<i className="fa-solid fa-money-bill-transfer ms-1 fs-2"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-active-primary pb-4"
                        data-bs-toggle="tab"
                        href="#kt_user_view_overview_events_and_logs_tab"
                      >
                        Paid Fee<i className="fa-solid fa-file-invoice ms-1 fs-2"></i>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="kt_user_view_overview_tab"
                      role="tabpanel"
                    >
                      <div className="card card-flush mb-6 mb-xl-9 d-md-block d-lg-block d-none">
                        <div className="card-header mt-6">
                          <div className="card-title">
                            <h2 className="mb-1">Contact Details <i className="fa-regular fa-address-book text-dark fs-3"></i></h2>
                          </div>
                        </div>
                        <div className="card-body p-9 pt-4">
                          <div className="tab-conent">
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <table className="table">
                                  <tbody className="text-start">
                                    <tr className="text-start">
                                      <td className="d-flex"><i className="fa-solid fa-phone me-1 mt-1 text-dark"></i>Mobile</td>
                                      <th className="">{studentInfoData && studentInfoData.MobileNo ? studentInfoData.MobileNo : '---'}
                                        <span className="text-light-info d-md-none d-lg-none"></span>
                                      </th>
                                    </tr>
                                    <tr>
                                      <td className="d-flex"><i className="fa-solid fa-blender-phone me-1 mt-1 text-dark"></i>Land</td>
                                      <th className="">{studentInfoData && studentInfoData.PhoneNo ? studentInfoData.PhoneNo : '---'}</th>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className="col-12 col-md-6">
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <td className="d-flex"><i className="fa-solid fa-location-dot text-dark me-1 mt-1"></i>Address</td>
                                      <th className="">
                                        {studentInfoData && studentInfoData.Address ? studentInfoData.Address : '---'}
                                      </th>
                                    </tr>
                                    <tr>
                                      <td className="d-flex"><i className="fa-regular fa-envelope text-dark me-1 mt-1"></i>Email</td>
                                      <th className="">
                                        {studentInfoData && studentInfoData.Email ? studentInfoData.Email : '---'}
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card card-flush mb-6 mb-xl-9 d-lg-none d-md-none d-block">
                        <div className="card-header mt-6">
                          <div className="card-title">
                            <h2 className="mb-1">Contact Details <i className="fa-regular fa-address-book text-dark fs-3"></i></h2>
                          </div>
                        </div>
                        <div className="card-body p-9 pt-4">
                          <div className="tab-conent">
                            <div className="row">
                              <div className="col-4 text-left">
                                <label className="form-label"><i className="fa-solid fa-phone me-1 mt-1 text-dark"></i>Mobile</label>
                              </div>
                              <div className="col-8 text-right">
                                <label className="form-label fw-bold">
                                  {studentInfoData && studentInfoData.MobileNo ? studentInfoData.MobileNo : '---'}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4 text-left">
                                <label className="form-label"><i className="fa-solid fa-blender-phone me-1 mt-1 text-dark"></i>Land</label>
                              </div>
                              <div className="col-8 text-right">
                                <label className="form-label fw-bold">
                                  {studentInfoData && studentInfoData.PhoneNo ? studentInfoData.PhoneNo : '---'}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4 text-left">
                                <label className="form-label"><i className="fa-solid fa-location-dot text-dark me-1 mt-1"></i>Address</label>
                              </div>
                              <div className="col-8 text-right">
                                <label className="form-label fw-bold">
                                  {studentInfoData && studentInfoData.Address ? studentInfoData.Address : '---'}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4 text-left">
                                <label className="form-label"><i className="fa-regular fa-envelope text-dark me-1 mt-1"></i>Email</label>
                              </div>
                              <div className="col-8 text-right">
                                <label className="form-label fw-bold">
                                  {studentInfoData && studentInfoData.Email ? studentInfoData.Email : '---'}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card card-flush mb-6 mb-xl-9 d-md-block d-lg-block d-none">
                        <div className="card-header mt-6">
                          <div className="card-title">
                            <h2 className="mb-1">Parent Details <img src={VisitSvg} alt="parent" style={{ height: '2rem' }} /> </h2>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="tab-content">
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <td>Father</td>
                                      <th className="">{studentInfoData && studentInfoData.Father ? studentInfoData.Father : '---'}</th>
                                    </tr>
                                    <tr>
                                      <td>Father Aadhaar</td>
                                      <th className="">
                                      {studentInfoData && studentInfoData.FatherAadhar ? studentInfoData.FatherAadhar : '---'}
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>Father Qualification</td>
                                      <th className="">{studentInfoData && studentInfoData.FatherQualification ? studentInfoData.FatherQualification : '---'}</th>
                                    </tr>
                                    <tr>
                                      <td>Father Occupation</td>
                                      <th className="">
                                        {studentInfoData && studentInfoData.FatherOccupation ? studentInfoData.FatherOccupation : '---'}
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-12 col-md-6">
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <td>Mother</td>
                                      <th className="">{studentInfoData && studentInfoData.MotherName ? studentInfoData && studentInfoData.MotherName : '---'}
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>Mother Aadhaar</td>
                                      <th className="">
                                        {studentInfoData && studentInfoData.MotherAadhar ? studentInfoData.MotherAadhar : '---'}
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>Mother Qualification</td>
                                      <th className="">{studentInfoData && studentInfoData.MotherQualification ? studentInfoData.MotherQualification : '---'}
                                        <span className="text-light-danger">jjj</span>
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>Mother Occupation</td>
                                      <th className="">
                                        {studentInfoData && studentInfoData.MotherOccupation ? studentInfoData.MotherOccupation : '---'}
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card card-flush mb-6 mb-xl-9 d-lg-none d-md-none d-block">
                        <div className="card-header mt-6">
                          <div className="card-title">
                            <h2 className="mb-1">Parent Details <img src={VisitSvg} alt="parent" style={{ height: '2rem' }} /> </h2>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="tab-content">
                            <div className="row">
                              <div className="col-6 text-left">
                                <label className="form-label">Father</label>
                              </div>
                              <div className="col-6 text-right">
                                <label className="form-label fw-bold">
                                  {studentInfoData && studentInfoData.Father ? studentInfoData.Father : '---'}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 text-left">
                                <label className="form-label">Father Aadhaar</label>
                              </div>
                              <div className="col-6 text-right">
                                <label className="form-label fw-bold">
                                  {studentInfoData && studentInfoData.FatherAadhar ? studentInfoData.FatherAadhar : '---'}
                                </label>
                              </div>
                            </div>                        
                            <div className="row">
                              <div className="col-6 text-left">
                                <label className="form-label">Father Qualification</label>
                              </div>
                              <div className="col-6 text-right">
                                <label className="form-label fw-bold">
                                  {studentInfoData && studentInfoData.FatherQualification ? studentInfoData.FatherQualification : '---'}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 text-left">
                                <label className="form-label">Father Occupation</label>
                              </div>
                              <div className="col-6 text-right">
                                <label className="form-label fw-bold">
                                  {studentInfoData && studentInfoData.FatherOccupation ? studentInfoData.FatherOccupation : '---'}
                                </label>
                              </div>
                            </div>

                            <br/>

                            <div className="row">
                              <div className="col-6 text-left">
                                <label className="form-label">Mother</label>
                              </div>
                              <div className="col-6 text-right">
                                <label className="form-label fw-bold">
                                  {studentInfoData && studentInfoData.MotherName ? studentInfoData.MotherName : '---'}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 text-left">
                                <label className="form-label">Mother Aadhaar</label>
                              </div>
                              <div className="col-6 text-right">
                                <label className="form-label fw-bold">
                                  {studentInfoData && studentInfoData.MotherAadhar ? studentInfoData.MotherAadhar : '---'}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 text-left">
                                <label className="form-label">Mother Qualification</label>
                              </div>
                              <div className="col-6 text-right">
                                <label className="form-label fw-bold">
                                  {studentInfoData && studentInfoData.MotherQualification ? studentInfoData.MotherQualification : '---'}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 text-left">
                                <label className="form-label">Mother Occupation</label>
                              </div>
                              <div className="col-6 text-right">
                                <label className="form-label fw-bold">
                                  {studentInfoData && studentInfoData.MotherOccupation ? studentInfoData.MotherOccupation : '---'}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div
                      className="tab-pane fade"
                      id="kt_user_view_overview_security"
                      role="tabpanel"
                    >
                     
                      <div className="card pt-1 mb-6 mb-xl-9">
                        <div className="card-header border-0">
                          <div className="card-title flex-column">
                            <h2>Fee Commitment</h2>
                          </div>
                        </div>
                        <div className="card-body pb-5">
                          <table className="table align-middle table-row-dashed gy-5">
                            <thead className="bg-light-primary">
                              <tr style={{ height: '40px' }} className="bg-light-primary fw-bold">
                                <th colSpan={1} className="text-center">#</th>
                                <th>Category</th>
                                <th className="d-flex text-end"><i className="fa-solid fa-indian-rupee-sign me-1 mt-1 text-dark"></i>Discount</th>
                                <th><i className="fa-solid fa-indian-rupee-sign me-1 text-dark text-end"></i>Due</th>
                              </tr>
                            </thead>
                            {/* <hr/> */}
                            <tbody className="fs-6 fw-semibold text-gray-600">
                              {loading ? (
                                <tr className="text-center">
                                    <td colSpan="12">Loading...</td>
                                </tr>
                              ) : studentFeesData && studentFeesData.length === 0 ? (
                                <tr>
                                    <td colSpan="4" className="text-center">No data available.</td>
                                </tr>
                              ) : (
                                studentFeesData.map((item, index) => (
                                  <tr key={index}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="fw-semibold">{item.FeeCategory}</td>
                                    <td className="text-end-sm">{item.Discount === 0 ? 0 : formatAmount(item.Discount)}</td>
                                    <td className="text-end-sm">{item.DueAmount === 0 ? 0 : formatAmount(item.DueAmount)}</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="kt_user_view_overview_events_and_logs_tab"
                      role="tabpanel"
                    >
                      <div className="card pt-4 mb-6 mb-xl-9">
                        <div className="card-header border-0">
                          <div className="card-title">
                            <h2>Fee Paid Details</h2>
                          </div>
                            <img 
                              src={DownlaodPDfGif}
                              className={studentFeePaidAmt && studentFeePaidAmt.length !== 0 ? 'd-block' : 'd-none'}
                              style={{ width: '4.7rem', height: '4.4rem' }}
                              alt="downlaodgif" 
                              onClick={handleDownloadPdf}
                            />
                        </div>
                        <div className="card-body pt-0 pb-5">
                          <div className="table-responsive d-md-block d-lg-block d-sm-none d-xs-none d-none">
                            <table
                              className="table align-middle table-row-dashed gy-5"
                              id="kt_table_users_login_session"
                            >
                              <thead className="">
                                <tr className="bg-light-warning fw-bold">
                                  <th>#</th>
                                  <th className="min-w-100px">Category</th>
                                  <th className="fw-bold"><i className="fa-solid fa-indian-rupee-sign me-1 text-dark"></i>Amount</th>
                                  <th className="fw-bold min-w-100px">Date</th>
                                </tr>
                              </thead>
                              <tbody className="fs-6 fw-semibold text-gray-600">
                                {loading ? (
                                  <tr className="text-center">
                                      <td colSpan="12">Loading...</td>
                                  </tr>
                                ) : studentFeePaidAmt && studentFeePaidAmt.length === 0 ? (
                                  <tr>
                                      <td colSpan="4" className="text-center">No data available.</td>
                                  </tr>
                                ) : (
                                studentFeePaidAmt.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                      <td className="fw-semibold">{item.FeeCatName}</td>
                                      <td>{formatAmount(item.Amount)}</td>
                                      <td>
                                        {new Date(item.PaidDate).toLocaleDateString('en-GB', {
                                          day: '2-digit',
                                          month: '2-digit',
                                          year: 'numeric'
                                        })}
                                      </td>
                                  </tr>
                                ))
                              )}
                              </tbody>
                            </table>
                          </div>
                           <div className="col-md-6 col-xl-4 d-md-none d-lg-none d-sm-block d-xs-block">
                            <div className="card border-hover-primary">
                              {studentFeePaidAmt.map((item, index) => (
                                <div className="card-header border-0 pt-9" key={index}>
                                  <div className="card-title m-0">
                                    <div className="symbol d-flex flex-column">
                                      <label className="form-label">{item.FeeCatName}</label>
                                      <span className="badge badge-light-primary fw-bold me-auto py-3 fs-3">
                                        {new Date(item.PaidDate).toLocaleDateString('en-GB', {
                                          day: '2-digit',
                                          month: '2-digit',
                                          year: 'numeric'
                                        })}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="card-toolbar">
                                    <span className="badge badge-light-success fw-bold me-auto py-3">
                                      {formatAmount(item.Amount)}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
            <div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
              <div className="text-gray-900 order-2 order-md-1">
                <span className="text-muted fw-semibold me-1">2024&copy;</span>
                <a
                  href="https://perennialcode.com"
                  target="_blank"
                  className="text-gray-800 text-hover-primary"
                >
                  Perennialcode
                </a>
              </div>
              {/* <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                <li className="menu-item">
                  <a
                    href="https://keenthemes.com"
                    target="_blank"
                    className="menu-link px-2"
                  >
                    About
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="https://devs.keenthemes.com"
                    target="_blank"
                    className="menu-link px-2"
                  >
                    Support
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="https://1.envato.market/EA4JP"
                    target="_blank"
                    className="menu-link px-2"
                  >
                    Purchase
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>


      {/* Print Content Start */}
      <div className="c m-auto card mb-10" ref={receiptRef} style={{ display: 'none', width: '33rem', height: '85rem' }} id="receipt-content">
        <div className="card-body" style={{ height: '100%' }}>
            <div className="d-flex justify-content-center align-items-center mb-3">
                <img src={BirlaPrintLogo} alt="birlalogo" className="text-center" style={{ width: '6rem' }} />
            </div>
            <h3 className="text-center mb-5 mt-5">Receipt Sample-copy</h3>
            <div className="row">
              <div className="d-flex col-12">
                <div className="col-6">
                    <table className="col-12 fs-3">
                      <tbody>
                          <tr>
                              <td>Receipt</td>
                              <th>{studentFeePaidAmt[0]?.ReceiptNo}</th>
                          </tr>
                          <tr>
                              <td>Name</td>
                              <th>{studentInfoData && studentInfoData.StudentName}</th>
                          </tr>
                          <tr>
                              <td>C/O</td>
                              <th>{studentInfoData && studentInfoData.Father}</th>
                          </tr>
                      </tbody>
                    </table>
                </div>
                <div className="col-6">
                    <table className="col-12 fs-3">
                      <tbody>
                          <tr>
                              <td>Date</td>
                              <th>
                                {new Date(studentFeePaidAmt[0]?.PaidDate).toLocaleDateString('en-GB', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric'
                                })}
                              </th>
                          </tr>
                          <tr>
                              <td>Class</td>
                              <th>{studentInfoData && studentInfoData.Standard}</th>
                          </tr>
                      </tbody>
                    </table>
                </div>
              </div>
            </div>

            <div className="col-12 fs-3 mt-4">
              <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Pariculars</th>
                        <th className="text-end"><i className="fa-solid fa-indian-rupee-sign me-1 text-dark"></i> Amount</th>
                    </tr>
                </thead>
                <tbody>
                  {studentFeePaidAmt.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.FeeCatName}</td>
                      <td className="text-end">{formatAmount(item.Amount)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        </div>
      </div>
      {/* Print Content End */}

      <style>
        {`
          .offcanvas.custom-offcanvas {
            width: 400px !important; /* Adjust this as needed */
          }

          @media (max-width: 576px) {
            .offcanvas.custom-offcanvas {
              width: 70% !important; /* Adjust the percentage as needed */
            }
          }
        `}
      </style>

      <div
        className="offcanvas offcanvas-end custom-offcanvas"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Recent Requests</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="">
            {loading ? (
              <h6 className=" text-center">
                Loading
                <span className="spinner-border spinner-border-sm text-dark ms-1"></span>
              </h6>
              ) : recentData && recentData.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center">
                    No data available
                  </td>
                </tr>
              ) : (
                recentData.map((item, index) => (
                  <div className="card border-hover-primary mb-3" key={index}>
                    <div className="card-header border-0 pt-9">
                      <div className="card-title m-0">
                        <div className="symbol">
                          <label className="form-label">Applied on</label>
                          <h2>{removeAmPmAndTime(item.CreatedOn)}</h2>
                        </div>
                      </div>

                      <div className="card-toolbar">
                        <span className={`badge ${getStatusColor(item.Status)} fw-bold me-auto py-3`}>
                          {getStatusInfo(item.Status).name}
                        </span>
                      </div>
                    </div>

                    <div className="card-body p-9">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>Visitor </td>
                            <th className="fw-semibold">{item.VisitorName}</th>
                          </tr>
                          <tr>
                            <td>Reason</td>
                            <th className="fw-semibold">{item.Reason}</th>
                          </tr>
                        </tbody>
                      </table>

                      <div className="d-flex flex- mb-5">
                        <div className="border border-gray-300 border-dashed rounded min-w-85px py-3 me-3 mb-3">
                          <div className="fs-6 text-gray-800 fw-bold">
                            {removeAmPm(item.StartDateTime)}
                          </div>
                          <div className="fw-semibold text-gray-500">
                            Pickup Date
                          </div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-85px py-3 mb-3">
                          <div className="fs-6 text-gray-800 fw-bold">
                            {removeAmPm(item.EndDateTime)}
                          </div>
                          <div className="fw-semibold text-gray-500">
                            Drop Date
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </div>

    </div>
  );
}
