

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './Config';
import '../CSS/signinnew.css';
import BgImg from '../Images/vbb.jpg';
import BirlaLogo from '../Images/birla.png';
import Swal from "sweetalert2";

export default function ChangePassword() {

    const navigate = useNavigate();

    const [userDetails, setUserDetails] = useState([]);

    useEffect(() => {
    
        const storedUserDetails = sessionStorage.getItem("userDetails");
        if (storedUserDetails) {
            const parsedUserDetails = JSON.parse(storedUserDetails);
            setUserDetails(parsedUserDetails);
        }
      }, []);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
        setErrorMessage('');
        setShowOldPassword(false);
        setShowNewPassword(false);
        setShowConfirmPassword(false);
    };
  
    const handleDetailsSubmit = async (e) => {
      e.preventDefault();
      if (userDetails) {

        if (oldPassword !== userDetails.password) {
            setErrorMessage("Old passowrd dosen't match.");
            return;
        }
        if (newPassword !== confirmPassword) {
            setErrorMessage('New and confirm password must be the same.');
            return;
        }
    
        setErrorMessage('');
        setLoading(true);
    
        try {
            const response = await fetch(`${API_BASE_URL}login/UpdatePassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    mobileNumber: userDetails.mobile,
                    Password: newPassword,
                }),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                if (data.Status) {
                    setLoading(false);
            
                    Swal.fire({
                        title: "Success",
                        text: `${data.Message}`,
                        icon: "success",
                    }).then((data) => {
                    // Reload the page after the user acknowledges the success message
                    if (data.isConfirmed || data.isDismissed) {
                        // window.location.reload();
                        navigate('/');
                    }
                    });
                    } else {
                        setLoading(false);
                        Swal.fire({
                            title: "Error",
                            text: `${data.Message}`,
                            icon: "error",
                        });
                    }
            } else {
                setErrorMessage(data.error || 'Failed to update password');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('An error occurred while updating the password.');
        } finally {
            setLoading(false);
        }
      }
    };

    const toggleShowPassword = (setShowPassword, showPassword) => () => {
        setShowPassword(!showPassword);
    };

    return(
        <div>
            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 text-center mb-5">
                            <h2 className="">
                                <img src={BirlaLogo} style={{ width: '8rem' }} alt="Birla Logo" />
                            </h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-7 col-lg-5">
                            <div className="wrap">
                                <div className="img" style={{ backgroundImage: `url(${BgImg})` }}></div>
                                <div className="login-wrap p-3 p-md-5">
                                    <div className="d-flex">
                                        <div className="w-100">
                                            <h1 className="mb-4 fw-semibold text-center">Change Password</h1>
                                        </div>       
                                    </div>
                                    <form onSubmit={handleDetailsSubmit} className={`card-body m-3`}>
                                        <div className="form-group mt-1">
                                            <label className="form-label" htmlFor="oldPassword">Old Password</label>
                                            <div className="input-group mb-2">
                                                <input 
                                                    type={showOldPassword ? 'text' : 'password'}
                                                    className="form-control bg-light-primary" 
                                                    placeholder='Enter your old password'
                                                    autoComplete='off' 
                                                    value={oldPassword}
                                                    onChange={handleInputChange(setOldPassword)}
                                                    required
                                                />
                                                <div className="input-group-append">
                                                    <span 
                                                        className="input-group-text cursor-pointer bg-light-info border border-light-danger"
                                                        onClick={toggleShowPassword(setShowOldPassword, showOldPassword)}
                                                        style={{ height: '3.4rem' }}
                                                    >
                                                        {showOldPassword ? <i className="fa-regular fa-eye-slash fs-4"></i> : <i className="fa-regular fa-eye fs-4"></i>}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mt-1">
                                            <label className="form-label" htmlFor="newPassword">New Password</label>
                                            <div className="input-group mb-2">
                                                <input 
                                                    type={showNewPassword ? 'text' : 'password'}
                                                    className="form-control bg-light-primary" 
                                                    placeholder='Enter your old password'
                                                    autoComplete='off' 
                                                    value={newPassword}
                                                    onChange={handleInputChange(setNewPassword)}
                                                    required
                                                />
                                                <div className="input-group-append">
                                                    <span 
                                                        className="input-group-text cursor-pointer bg-light-info border border-light-danger"
                                                        onClick={toggleShowPassword(setShowNewPassword, showNewPassword)}
                                                        style={{ height: '3.4rem' }}
                                                    >
                                                        {showNewPassword ? <i className="fa-regular fa-eye-slash fs-4"></i> : <i className="fa-regular fa-eye fs-4"></i>}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mt-1">
                                            <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                                            <div className="input-group mb-2">
                                                <input 
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    className="form-control bg-light-primary" 
                                                    placeholder='Enter your old password'
                                                    autoComplete='off' 
                                                    value={confirmPassword}
                                                    onChange={handleInputChange(setConfirmPassword)}
                                                    required
                                                />
                                                <div className="input-group-append">
                                                    <span 
                                                        className="input-group-text cursor-pointe bg-light-info border border-light-danger"
                                                        onClick={toggleShowPassword(setShowConfirmPassword, showConfirmPassword)}
                                                        style={{ height: '3.4rem' }}
                                                    >
                                                        {showConfirmPassword ? <i className="fa-regular fa-eye-slash fs-4"></i> : <i className="fa-regular fa-eye fs-4"></i>}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                            {/* <div></div> */}

                                            <Link to='/dashboard'>
                                                <a className="link-primary">
                                                    <i className="fa-solid fa-arrow-left me-1 text-primary mt-1"></i>Back to home
                                                </a>
                                            </Link>
                                            <Link to='/'>
                                                <a className="link-primary">
                                                    <i className="fa-solid fa-arrow-left me-1 text-primary mt-1"></i>Back to Signin
                                                </a>
                                            </Link>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="form-control btn btn-success rounded submit px-3">
                                                {loading ? 'Submitting..' : 'Submit'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
	        </section>
        </div>
    )
}