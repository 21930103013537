import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Logout from './Components/Functions/Logout';
import StudentFee from './Components/Functions/StudentFee';
// import SignIn from './Components/Functions//SignInold';
import StudentInfo from './Components/Functions/StudentInfo';
import Dashboard from './Components/Functions/Dashboard';
import ForgotPassword from './Components/Functions/ForgotPassword';
import ChangePassword from './Components/Functions/ChangePassword';
import SigninNew from './Components/Functions/SigninNew';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          {/* <Route path="/signinold" element={<SignIn />} /> */}
          <Route path="" element={<SigninNew />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/student-info" element={<StudentInfo />} />
          <Route path="/student-fee" element={<StudentFee />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
