
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, BASE_SUPERID } from './Config';
import '../CSS/signinnew.css';
import BgImg from '../Images/vbb.jpg';
import BirlaLogo from '../Images/birla.png';

export default function ForgotPassword() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [emailId, setEmailId] = useState('');
    const [mobileNumberMessage, setMobileNumberMessage] = useState('');

    const handleEmail = (e) => {
        setEmailId(e.target.value);
        setMobileNumberMessage('');
    }

    const handleDetailsSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMobileNumberMessage('');

        try {
            const response = await fetch(`${API_BASE_URL}login/ForgotPassword?emailId=${emailId}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();

            if (result.Status) {
                // If password reset successful, send email
                const emailData = {
                    superid: BASE_SUPERID, // Replace with your superid
                    toaddr: emailId,
                    message: `Your password is: ${result.ResultData[0].Password}`,
                    subject: 'Your password has been reset successfully.', // Email subject
                };

                const emailResponse = await fetch('https://notifyservisesrc.azurewebsites.net/notify/sendmail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(emailData)
                });

                if (!emailResponse.ok) {
                    throw new Error('Email sending failed');
                }

                setLoading(false);
                navigate('/');
            } else {
                setLoading(false);
                setMobileNumberMessage('*Email not registered.');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
            setMobileNumberMessage('*Error while fetching, try after sometime.');
        }
    }

    return (
        <div>
            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 text-center mb-5">
                            <h2 className="">
                                <img src={BirlaLogo} style={{ width: '8rem' }} alt="Birla Logo" />
                            </h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-7 col-lg-5">
                            <div className="wrap">
                                <div className="img" style={{ backgroundImage: `url(${BgImg})` }}></div>
                                <div className="login-wrap p-3 p-md-5">
                                    <div className="d-flex">
                                        <div className="w-100">
                                            <h1 className="mb-4 fw-semibold text-center">Forgot Password</h1>
                                        </div>
                                    </div>
                                    <form onSubmit={handleDetailsSubmit} className={`card-body m-3`}>
                                        <p className='fw-bold text-info'>*Password will sent to your registered email.</p>
                                        <div className="form-group mt-1">
                                            <label className="form-label" htmlFor="username">EmailId</label>
                                            <input
                                                type="email"
                                                className="form-control mb-2"
                                                placeholder='Enter registered email address'
                                                autoComplete='off'
                                                onChange={handleEmail}
                                                required
                                            />
                                        </div>

                                        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                            <div></div>
                                            <Link to='/'>
                                                <a className="link-primary">
                                                    <i className="fa-solid fa-arrow-left me-1 text-primary mt-1"></i>Back to Signin
                                                </a>
                                            </Link>
                                        </div>
                                        <p className='fw-semibold text-danger'>{mobileNumberMessage}</p>
                                        <div className="form-group">
                                            <button type="submit" className="form-control btn btn-success rounded submit px-3">{loading ? 'Submitting..' : 'Submit'}</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
